$(document).ready(function () {

  $('.slick').slick({
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    dots: true,
    prevArrow: `<svg class='prev-arrow'><use xlink:href='/wp-content/themes/taaTheme/svg-sprite/sprite.svg#long-arrow'></use></svg>`,
    nextArrow: `<svg class='next-arrow'><use xlink:href='/wp-content/themes/taaTheme/svg-sprite/sprite.svg#long-arrow'></use></svg>`,
  });
  
  let arrowsPositionFrame = function() {
    let FramePrevArrow = document.querySelector('.frame-slider .prev-arrow')
    let FrameNextArrow = document.querySelector('.frame-slider .next-arrow')
    let FrameDots = document.querySelectorAll('.frame-slider .slick-dots li')

    if(FrameNextArrow) {
      if(window.innerWidth > 992) {
        let dotsCount = FrameDots.length
        let translate = 480 - (32 * dotsCount + 31 + 16)
        FramePrevArrow.style.transform = ''
        FrameNextArrow.style.transform = `translateX(-${translate}px)`
      } else {
        let dotsCount = FrameDots.length
        let translate = (32 * dotsCount / 2) + 16
        FramePrevArrow.style.transform = `rotate(180deg) translateX(calc(${translate}px + 15px))`
        FrameNextArrow.style.transform = `translateX(calc(${translate}px - 15px))`
      }
    }
  }

  arrowsPositionFrame()
  window.addEventListener('resize', arrowsPositionFrame)

  $('.tile-slick').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    dots: true,
    prevArrow: `<svg class='prev-arrow'><use xlink:href='/wp-content/themes/taaTheme/svg-sprite/sprite.svg#long-arrow'></use></svg>`,
    nextArrow: `<svg class='next-arrow'><use xlink:href='/wp-content/themes/taaTheme/svg-sprite/sprite.svg#long-arrow'></use></svg>`,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  
  let arrowsPositionTile = function() {
    let TilePrevArrow = document.querySelector('.tile-slider .prev-arrow')
    let TileNextArrow = document.querySelector('.tile-slider .next-arrow')
    let TileDots = document.querySelectorAll('.tile-slider .slick-dots li')
    if(TilePrevArrow && TileNextArrow) {
      let dotsCount = TileDots.length
      let translate = (32 * dotsCount / 2) + 16
      TilePrevArrow.style.transform = `rotate(180deg) translateX(calc(${translate}px + 15px))`
      TileNextArrow.style.transform = `translateX(calc(${translate}px - 15px))`
    }
  }

  arrowsPositionTile()
  window.addEventListener('resize', function() {
    setTimeout(arrowsPositionTile, 100);
  })

});
