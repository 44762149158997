(function() {
  const $header = $('.page-header');

  let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

  const sticky = $header.offset().top + $('.page-header__inner').height();

  function stickyHeader() {
    var st = window.pageYOffset || document.documentElement.scrollTop;

    if (st > lastScrollTop) {
      // downscroll code
      $header.removeClass('scrolled--visible');
    } else {
      // upscroll code
      $header.addClass('scrolled--visible');
    }

    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

    $header.toggleClass('scrolled', window.pageYOffset > sticky);

    if (window.pageYOffset <= sticky) {
      $header.removeClass('scrolled--visible scrolled');
    }
  }

  $(window).on('scroll load', stickyHeader);
})();
